import React from 'react'

import Layout from '../components/layout'
import {Header, Container} from 'semantic-ui-react'

const ContactPage = () => (
  <Layout>
    <Container style={{marginTop: '4em'}} textAlign='center'>
    <Header as='h1'>
        Contact
    </Header>
    </Container>
  </Layout>
)

export default ContactPage